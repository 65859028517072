import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BigHeader from "../components/bigHeader"

// import t from "locale"

const Jobs = ({ contentField }) => {
  const lang = "en"
  // const [modal, setModal] = useState(false)
  const seo = contentField.seo
  const image = contentField.featuredImage
  return (
    <Layout
      lang={lang}
      // translationDE="/de/arbeitsplaetze/"
      // translationES="/es/trabajos/"
      // translationIT="/it/lavori/"
      // translationFR="/fr/emplois/"
    >
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <BigHeader
        title="Jobs"
        image={require("../assets/images/jobs-back.jpg")}
      />

      <div className="main-section">
        <div
          className="text-container"
          dangerouslySetInnerHTML={{ __html: contentField.content }}
        />
        {/* <div className="text-container">
          <a
            href="/"
            onClick={e => {
              e.preventDefault()
              setModal(true)
            }}
          >
            {t("See video", lang)}
          </a>
          {modal && (
            <>
              <div className="subpage-modal">
                <div className="subpage-modal__wrapper">
                  <button
                    className="subpage-modal__close"
                    onClick={e => {
                      setModal(false)
                    }}
                  >
                    {t("Close", lang)}
                  </button>
                  <div className="embed-container">
                    <ReactPlayer
                      url="https://vimeo.com/477943538"
                      width="100%"
                      heigth="100%"
                      playing={modal === true ? true : false}
                      controls={true}
                      muted={false}
                    />
                  </div>
                </div>
              </div>
              <div
                className="subpage-modal__overlay"
                onClick={e => {
                  setModal(false)
                }}
              ></div>
            </>
          )}
        </div> */}
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDozMDky" }) {
          content
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <Jobs {...data} />}
  />
)
